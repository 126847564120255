import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import GlobalLayout from "../layouts/globalLayout";
import SectionHeader from "../components/SectionHeader/sectionHeader";
import styles from './about.module.css';


const imagesQuery = graphql`
  query {
    drPic: file(
      relativePath: { eq: "about/James_Lally_pic.png" }
    ) {
      childImageSharp {
        fixed(height: 180) {
          ...GatsbyImageSharpFixed
        }
      }
    },
    christinaPic: file(
      relativePath: { eq: "about/about_pic_christina.jpg" }
    ) {
      childImageSharp {
        fixed(height: 220) {
          ...GatsbyImageSharpFixed
        }
      }
    },
    lexiePic: file(
      relativePath: { eq: "about/lexie.jpg" }
    ) {
      childImageSharp {
        fixed(height: 220) {
          ...GatsbyImageSharpFixed
        }
      }
    },
  }
`

export default function About() {
  const pictures = useStaticQuery(imagesQuery);

  return (
    <GlobalLayout bodyBackgroundColor="rgb(212, 212, 212)">
      <div className={styles.container}>
        <div className={styles.text__container}>
          <SectionHeader title="About Us" className={styles.header} />
          <p className={styles.paragraph}>
            Skin Solutions Aesthetics offers a variety of skincare enhancements that will provide a total skincare experience. 
            Led by James M. Lally, DO, MMM and Christina Karras PA-C, our goal is to make customers love their skin by offering 
            natural looking enhancements with beautiful results. Christina Karras PA-C has 10 years experience in Dermatology and Aesthetics 
            and strives to provide excellent results while taking the time to educate her patients and recommend a skincare plan customized just for them.
          </p>
          <PersonPicAndDescription 
            personName="Christina Karras, PA-C" 
            personTitle="" 
            personDescription="Christina Karras is a certified Physician Assistant who completed her Masters of Physician Assistant Studies at Western University of Health Science in 2010. Upon graduation, she received the Blake Award of Academic Excellence for achieving the highest grade point average of her class, as well as the highest in the School of Allied Health Professionals. She has specialized in Dermatology for over 10 years and is well trained in clinical dermatology, aesthetics and cosmetic surgery. She is highly experienced in dermal fillers, treatment options for facial volume loss, Botox, acne scars and laser treatments. Christina especially enjoys dermal filler techniques that create a natural looking enhancement. She hopes to make patients’ first time experience with cosmetic procedures enjoyable, by taking the time to consult them and answer all questions. She is the proud mom of two beautiful girls. On her free time she enjoys camping, home DIY projects, and crafting with her daughters. She is looking forward to meeting you and welcoming you to our SKIN Solutions Aesthetics family." 
            personPic={pictures.christinaPic} 
            height="220px"
            width="157px"
          />
          <PersonPicAndDescription 
            personName="Lexie Urbina" 
            personTitle="" 
            personDescription="Lexie Urbina is a licensed esthetician who completed courses at Salon Success Academy. She has received academic honor roll, The American Legion School Award, and The Golden State Seal Merit Diploma. She loves performing services such as the Diamond Glow microdermabrasion and facials. It is her mission to assist her clients in reaching their skin goals and helping them." 
            personPic={pictures.lexiePic} 
            height="220px"
            width="175px"
          />
          <PersonPicAndDescription 
            personName="James M. Lally, DO, MMM" 
            personTitle="Medical Director" 
            personDescription="James M. Lally, DO, MMM, is a recognized leader in the local healthcare arena.  He is the Chief Medical Officer for Health Service Alliance, incorporated which is a Federally Qualified Health Center Look-alike clinic.  He works to provide care to those most in need and the center was recently recognized by the Chino Valley Chamber of Commerce as their “Business of the Year 2020” for all the work and support provided to the local communities regarding COVID-19 testing, management, and vaccinations.    He is on the Board of Trustees for the American Osteopathic Association, Western University of the Health Sciences, and is a member of the University of La Verne’s Presidential Health Advisory Council.  He lives in Upland with his wife, Rebecca, and his twin girls." 
            personPic={pictures.drPic} 
            height="155px"
            width="180px"
          />
        </div>
      </div>
    </GlobalLayout>
  );
}

function PersonPicAndDescription({
  personName,
  personTitle,
  personDescription,
  personPic,
  height,
  width
}) {
  return (
    <div className={styles.person__row}>
      <div className={styles.person__pic}>
        <div className={styles.pic__container}>
          <Img
            style={{
              overflow: "hidden",
              width: width ? width : "100%",
              height: height ? height : "180px",
              borderRadius: '25px'
            }}
            objectPosition="left"
            fixed={personPic.childImageSharp.fixed}
            imgStyle={{ objectFit: "contain" , objectPosition: 'center'}}
          />
        </div>
        <div className={styles.person__title}>
          {personName}
          <br />
          <div className={styles.person__company__title}>
            {personTitle}
          </div>
        </div>
      </div>
      <div className={styles.person__description}> 
          {personDescription}
      </div>
    </div>
  )
}